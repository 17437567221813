<template>
  <v-col class="px-0 py-0">
    <v-data-table
      style="margin-top: 16px"
      :headers="headers"
      :items="orders"
      hide-default-footer
      hide-default-header
      :items-per-page="100"
      background-color="red"
      no-data-text="Поки що ніхто не купив квиток"
    >
      <template v-slot:header="{ props }">
        <th v-for="head in props.headers" :key="head.id">
          <div
            style="
              padding: 16px 20px;
              background: #fafafa;
              width: 100% !important;
            "
          >
            <v-row no-gutters align="center">
              <v-col class="px-0 py-0">
                <v-row
                  no-gutters
                  align="center"
                  :justify="head.align ? head.align : 'start'"
                  ><output
                    style="
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      letter-spacing: 0.1em;
                      color: #4b5262;
                      user-select: none;
                    "
                    >{{ head.text }}
                  </output>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </th>
      </template>
      <template v-slot:[`item.id`]="{ item }">
        <span class="tableItem" style="color: #144fa9">{{
          item.order_ref_id
        }}</span>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        <span class="tableItem" v-if="item.user"
          >{{ item?.user?.first_name }} {{ item?.user?.last_name }}</span
        >
        <span class="tableItem" v-else-if="item.manager"
          >{{ item?.manager?.first_name }} {{ item?.manager?.last_name }}</span
        >
      </template>
      <template v-slot:[`item.route`]="{ item }">
        <span class="tableItem"
          >{{
            item.departure.translations.find(
              (translate) => translate.lang == "ua"
            )?.name
          }}-{{
            item.destination.translations.find(
              (translate) => translate.lang == "ua"
            )?.name
          }}</span
        >
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span class="tableItem">{{
          new Date(item.created_at).toLocaleDateString("uk-UA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
        }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div
          style="
            border-radius: 4px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400 !important;
            padding: 4px 8px;
          "
          :style="
            item.status.toLowerCase() == 'payed'
              ? 'color:#1EB564; background: rgba(30, 181, 100, 0.1);'
              : item.status == 'Booking' || item.status == 'PreBooking' || item.status == 'PayInBus'
              ? 'color: #E19700; background: rgba(254, 213, 0, 0.10);'
              : 'color:#DF3A3A;background:rgba(223, 58, 58, 0.10);'
          "
        >
          {{
            item.status.toLowerCase() == "payed"
              ? "Оплачено"
              : item.status == "Booking" || item.status == "PreBooking"
              ? "Заброньовано"
              : item.status == "PayInBus"
              ? "Оплата при посадці"
              : "Скасовано"
          }}
        </div>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row no-gutters align="center" justify="end">
          <div
            style="
              color: #144fa9;
              font-weight: 400;
              cursor: pointer;
              user-select: none;
            "
            @click="(order = item), (showDetailOrderDrawer = true)"
          >
            деталі
          </div>
        </v-row>
      </template>
    </v-data-table>
    <detail-order-drawer
      v-if="showDetailOrderDrawer"
      :order="order"
      @close="showDetailOrderDrawer = false"
    />
  </v-col>
</template>

<script>
import detailOrderDrawer from "../../../UI/detailOrderDrawer.vue";
export default {
  components: { detailOrderDrawer },
  data: () => ({
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id",
        sortable: false,
      },
      { text: "Клієнт", value: "user", align: "start", sortable: false },
      {
        text: "Маршрут",
        value: "route",
        align: "start",
        sortable: false,
      },
      {
        text: "Дата",
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: "Статус",
        value: "status",
        align: "center",
        sortable: false,
      },
      { text: "Опції", value: "action", sortable: false, align: "end" },
    ],
    order: {},
    showDetailOrderDrawer: false,
  }),
  props: {
    orders: {
      require: true,
    },
  },
};
</script>

<style scoped>
.tableItem {
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>