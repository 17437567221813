<template>
  <v-col class="px-0 py-0">
    <loader v-if="showLoader" />
    <v-col v-else class="px-0 py-0">
      <div
        class="sectionBackground"
        v-if="loggedUserPTrans.role == 'dispatcher'"
      >
        <v-row no-gutters>
          <p class="sectionTitle" style="margin-bottom: 0px; font-weight: 700">
            Пошук рейсів
          </p>
          <v-row no-gutters justify="end">
            <span
              style="
                color: #144fa9;
                font-family: 'MacPaw Fixel';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                user-select: none;
                cursor: pointer;
              "
              @click="$router.push('/admin/trips')"
              >Дивитись всі</span
            >
          </v-row>
        </v-row>
        <admin-search-ticket-form style="margin-top: 20px" />
      </div>
      <v-row no-gutters align="center" style="margin: 20px 0px">
        <v-col class="py-0" style="padding-right: 10px">
          <charts-card
            title="Продано квитків"
            :numbers="yearlySellTicketStatistic.total"
            :data="yearlySellTicketStatistic.interval"
            subtitle="за увесь час"
          />
        </v-col>
        <v-col class="py-0" style="padding-left: 10px">
          <charts-card
            title="Загальна сума продаж"
            :numbers="yearlySellSumStatistic?.total?.toFixed(2)"
            :data="yearlySellSumStatistic.interval"
            subtitle="за увесь час"
          />
        </v-col>
      </v-row>
      <v-row no-gutters align="start">
        <v-col cols="8" class="py-0" style="padding-right: 20px">
          <div class="statisticCard">
            <v-row no-gutters align="center">
              <span>Історія продаж</span>
              <v-row no-gutters justify="end">
                <span
                  style="
                    color: #144fa9;
                    font-size: 14px;
                    font-weight: 400;
                    cursor: pointer;
                    user-select: none;
                  "
                  >Дивитися всі</span
                >
              </v-row>
            </v-row>
            <main-history-purschase-table :orders="orders" />
          </div>
        </v-col>
        <v-col cols="4" class="px-0 py-0">
          <div class="statisticCard">
            <p style="margin: 0px">Сьогоднішні продажі</p>
            <v-row no-gutters align="center" style="margin-top: 20px">
              <v-col cols="8" class="px-0 py-0">
                <p
                  style="font-size: 32px; font-weight: 600; margin-bottom: 4px"
                >
                  {{ daySellTicketStatistic.total }}
                </p>
                <span style="font-size: 14px; color: #989898; font-weight: 400"
                  >Статистика за день</span
                >
              </v-col>
              <!-- <v-row no-gutters align="center" justify="end">
                <v-icon color="#1eb564" size="16px" style="margin-right: 4px"
                  >mdi-arrow-up</v-icon
                >
                <span style="color: #1eb564; font-size: 14px; font-weight: 400"
                  >1%</span
                >
              </v-row> -->
            </v-row>
            <div
              ref="bar"
              style="width: 100%; height: 320px; margin-top: 100px"
            ></div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
import * as echarts from "echarts";
import chartsCard from "../../../UI/Cards/chartsCard.vue";
import MainHistoryPurschaseTable from "./mainHistoryPurschaseTable.vue";
import AdminSearchTicketForm from "../adminSearchTicketForm.vue";
import { mapGetters } from "vuex";
import orderHistoryService from "../../../../requests/Admin/orderHistoryService";
import statisticService from "../../../../requests/Admin/statisticService";
import Loader from "../../../UI/Loader.vue";
export default {
  components: {
    chartsCard,
    MainHistoryPurschaseTable,
    AdminSearchTicketForm,
    Loader,
  },
  data: () => ({
    orders: [],
    yearlySellTicketStatistic: {},
    yearlySellSumStatistic: {},
    daySellTicketStatistic: {},
    key: 0,
    showLoader: true,
  }),
  props: {
    isMini: {
      require: false,
    },
  },
  mounted() {
    this.getStatistic();
    this.getRecentlyOrders();
  },
  methods: {
    async getStatistic() {
      const promises = [
        await statisticService
          .getMainStatistic("Yearly", "TicketPayed", "", "")
          .then((res) => {
            if (res.status == "Success") {
              this.yearlySellTicketStatistic = res.data?.[0];
            }
          }),
        await statisticService
          .getMainStatistic("Yearly", "PricePayed", "", "")
          .then((res) => {
            if (res.status == "Success") {
              this.yearlySellSumStatistic = res.data?.[0];
            }
          }),
        await statisticService
          .getMainStatistic("Daily", "TicketPayed", "", "")
          .then((res) => {
            if (res.status == "Success") {
              this.daySellTicketStatistic = res.data?.[0];
            }
          }),
      ];
      Promise.all(promises).then(() => {
        this.showLoader = false;
        setTimeout(() => {
          this.initChart();
        }, 500);
      });
    },
    initChart() {
      const chart = echarts.init(this.$refs.bar);

      const option = {
        tooltip: {},
        xAxis: {
          type: "category",
          data: this.daySellTicketStatistic.interval.map((data) =>
            data.end.substring(11, 16)
          ),
          show: true,
        },
        yAxis: {
          type: "value",
          show: false,
        },
        grid: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          containLabel: true,
        },
        series: [
          {
            data: this.daySellTicketStatistic.interval.map(
              (data) => (data = data.count)
            ),
            type: "bar",
            itemStyle: {
              color: "#448DFD",
            },
          },
        ],
      };

      chart.setOption(option);
    },
    async getRecentlyOrders() {
      await orderHistoryService.getRecentlyOrders().then((res) => {
        if (res.status == "Success") {
          this.orders = res.data;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
  },
};
</script>

<style scoped>
.statisticCard {
  border-radius: 10px;
  background: #fff !important;
  padding: 16px;
  width: 100%;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>